import React, { useEffect, useState } from "react";

import "./App.css";
import "../src/comman/index.css";
import "./pages/index.css";
import "../src/index.css";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import AppRoutes from "./routes";
import LoaderContext from "./context/LoaderContext";
import AwsAmplifyInit from "./http/services/aws-amplify";
import { AuthContext, useAuth } from "./context/AuthContext";
import { SnackbarProvider, closeSnackbar } from "notistack";
import NotificationContext from "./context/NotificationContext";
import { AxiosInterceptorContextProvider } from "./context/AxiosInterceptor";
import {
  getAuthUser,
  getAuthUserProfile,
  getAuthUserValidated,
  getEnterVaultPin,
} from "./http/services/local-storage-service";
import { environment } from "./environment/environment";
// import '../src/pages/insurance/mockApi/insuranceMock';
const App = () => {
  AwsAmplifyInit();
  const [isLoader, setIsLoader] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [authUser, setAuthUser] = useState(null);
  useEffect(() => {
    if (environment?.inspect_element_disable === "true") {
      const handleContextMenu = (e) => {
        e.preventDefault();
      };
      document.addEventListener("contextmenu", handleContextMenu);
      const handleKeyDown = (e) => {
        if (
          (e.ctrlKey && e.shiftKey && e.key === "I") ||
          (e.ctrlKey && e.shiftKey && e.key === "J") ||
          (e.ctrlKey && e.key === "U") ||
          e.key === "F12"
        ) {
          e.preventDefault();
        }
      };
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("contextmenu", handleContextMenu);
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, []);
  useEffect(() => {
    if (environment?.inspect_element_disable === "true") {
      const detectDevTools = () => {
        const threshold = 160;
        if (
          window.outerWidth - window.innerWidth > threshold ||
          window.outerHeight - window.innerHeight > threshold
        ) {
          window.location.href = "https://www.google.com/";
        }
      };

      window.addEventListener("resize", detectDevTools);
      detectDevTools();

      return () => {
        window.removeEventListener("resize", detectDevTools);
      };
    }
  }, []);
  useEffect(() => {
    if (localStorage?.getItem("data")) {
      setAuthUser(
        getAuthUser() && getAuthUserProfile()
          ? {
              ...JSON.parse(getAuthUser()),
              authUserProfile: {
                ...JSON.parse(getAuthUserProfile()),
                is_vault_pin_enter: getEnterVaultPin() === true ? true : false,
              },
              authUserValidated:
                getAuthUserValidated() === false ? false : true,
            }
          : null
      );
    } else {
      localStorage.clear();
    }
  }, []);
  const { startAutoLogoutTimer, sessionAutoLogoutTimerClear } = useAuth();
  useEffect(() => {
    startAutoLogoutTimer();
    return () => sessionAutoLogoutTimerClear();
  }, []);

  return (
    <Router>
      <Provider store={store}>
        <AxiosInterceptorContextProvider>
          <NotificationContext.Provider
            value={{ notificationCount, setNotificationCount }}
          >
            <AuthContext.Provider value={{ authUser, setAuthUser }}>
              <LoaderContext.Provider value={{ isLoader, setIsLoader }}>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={3000}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  <ThemeProvider theme={theme}>
                    <AppRoutes />
                  </ThemeProvider>
                </SnackbarProvider>
              </LoaderContext.Provider>
            </AuthContext.Provider>
          </NotificationContext.Provider>
        </AxiosInterceptorContextProvider>
      </Provider>
    </Router>
  );
};

export default App;
